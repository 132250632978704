const wrapWord = (word, otherAttributes) => {
  if (/^[\W]$/.test(word)) return word;
  return word.replace(/(>| |^)([?!.,)(\]\[\-"%$':;¿—\w+À-ÿ\u3131-\uD79D]{1,})((?=(<| |$)))/g, `$1<span ${otherAttributes} data-word>$2</span>$3`);
}

const parseScriptInput = (input, otherAttributes = '') => {
  if (!input) return

  return input.replace(/[\u2018\u2019]/g, "'")
                        .replace(/[\u201C\u201D]/g, '"')
                        .replace(/style="color: black;"/g, '')
                        .split(/\s+|&nbsp;/g)
                        .map((word) => {
                          if (word.includes('/')) {
                            return word.split('/').map(subWord => wrapWord(subWord, otherAttributes)).join('/');
                          }
                          return wrapWord(word, otherAttributes);
                        }).join(' ')
}

export default parseScriptInput
